import { createSlice } from '@reduxjs/toolkit';
import {getBaseUrl} from "../../services/app.service";

const initialState = {
	currentDoc: 'Home',
	docs: {
		wacepayBusiness: `/API-specs/WacePay.postman_collection.json-OpenApi3Json.json`,
		NufiWhatsApp: `/API-specs/Nufi-SMS API.postman_collection.json-OpenApi3Json.json`,
		PayIn: `/API-specs/WacePay - Payin.postman_collection.json-OpenApi3Json.json`,
		NufiSMSGSM: ``,
		Home: '',
		// Ajoutez d'autres documents ici
	},
};

const docSlice = createSlice({
	name: 'doc',
	initialState,
	reducers: {
		setCurrentDoc: (state, action) => {
			state.currentDoc = action.payload;
		},
	},
});

export const { setCurrentDoc } = docSlice.actions;
export default docSlice.reducer;